import React, { Component } from 'react'
import OneAisle from './OneAisle'
import OneSpot from './OneSpot'

class SideView extends Component {
    constructor() {
        super()
        this.state = { levelOver: null }
    }

    render() {
        let board = []
        for (let i = 1; i < this.props.data.levelsCount + 1; i++) {

            let one_row = []

            //setting letter for level
            const letter = String.fromCharCode(65 + this.props.data.levelsCount - i)

            let position="left"
            let counter=this.props.data.leftChannelPostionsCount
            for (let j = 1; j < this.props.data.leftChannelPostionsCount + this.props.data.rightChannelPostionsCount + 1; j++) {
                let shuttle=false
                if(this.props.shuttle.position.level===letter&&this.props.shuttle.position.side===position&&this.props.shuttle.position.location===counter)
                shuttle=true
                //start : vertical labels - letters 
                if (j === 1)
                    one_row.push(<div key={"label-side-"+i + "_" + j} className="label-vertical"><span>{letter}</span></div>)

                //normal box 
                one_row.push(
                <OneSpot key={"side-box"+i + "_" + j} shuttle={shuttle} hovered={this.state.levelOver === letter}/>
                )

                //aisle between left and right side 
                if (j === this.props.data.leftChannelPostionsCount){
                    let mover=false,elevator=false,shuttle=false
                    let stacked=0

                    

                    if(letter===this.props.elevator.position.level) {elevator=true;stacked++}
                    if(letter===this.props.mover.position.level) {mover=true;stacked++}
                    if(letter===this.props.shuttle.position.level && this.props.shuttle.position.location===0) {shuttle=true;stacked++}

                    if(!shuttle && !mover && !elevator)
                        one_row.push(<OneAisle key={"side-aisle"+i + "_" + j} />)
                    else
                        one_row.push(<OneAisle elevator={elevator} mover={mover} shuttle={shuttle} stacked={stacked>1?true:false} key={"side-aisle"+i + "_" + j} />)  

                    position="right"
                    counter=0    
                }

                //end : vertical labels - letters 
                if (j ===  this.props.data.leftChannelPostionsCount + this.props.data.rightChannelPostionsCount)
                    one_row.push(<div key={"label-side-"+i + "_" + j} className="label-vertical"><span>{letter}</span></div>)

                position==="left"? counter-- : counter++
    

            }

            //pushing one row to array - hover and mousedown effects
            board.push(
            <div key={"side-row-"+i} className="d-flex flex-row one-level" onMouseOver={() => this.setState({ levelOver: letter })}
            onMouseLeave={() => this.setState({ levelOver: null })} onClick={() => { this.props.selectRow(letter) }}>
            {one_row}
            </div>
            )
        }

        return (
            <div className="d-flex flex-column pt-5 align-items-center">
                <p>Widok z boku</p>
                {board}
            </div>
        )
    }
}

export default SideView
