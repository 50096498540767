import React, { Component } from 'react'

class OneAisle extends Component {
    render() {
        let class_name = "one-box aisle"
        if(this.props.stacked)
            class_name += " icon-aisle stacked"
        else if (this.props.elevator)
            class_name += " icon-aisle elevator"
        else if (this.props.mover)
            class_name += " icon-aisle mover"
        else if (this.props.shuttle)
            class_name += " icon-aisle shuttle"
        return (
            <div className={class_name}></div>
        )
    }
}

export default OneAisle
