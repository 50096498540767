import React, { Component } from 'react'
import OneSpot from './OneSpot'
import OneAisle from './OneAisle'
class AboveView extends Component {
    constructor() {
        super()
        this.state = { selected:{row:null, column:null} }
    }

    getRowLabels=()=>{
        let labels=[]        
        labels.push(<div key={"label-left-empty"} className="label-horizontal"></div>)

        for(let i=this.props.data.leftChannelPostionsCount; i>0; i--){
            labels.push(<div key={"label-left"+i} className="label-horizontal">L{("00"+i).slice(-3)}</div>)
        }

        labels.push(<div key={"label-center-empty"} className="label-horizontal"></div>)

        for(let i=1; i<this.props.data.rightChannelPostionsCount + 1; i++){
            labels.push(<div key={"label-right"+i} className="label-horizontal">R{("00"+i).slice(-3)}</div>)
        }

        labels.push(<div key={"label-right-empty"} className="label-horizontal"></div>)

        return(<div key={"top-labels-div"} className="d-flex flex-row">{labels}</div>)
    }

    selectedBox=(row,column)=>{
        this.setState({selected:{row:row, column:column}})
    }

    render() {
        let board = []
        const shuttle_pos=this.props.shuttle.position
        for (let i = 1; i < this.props.data.channelsCount + 1; i++) {
            let one_row = []

            //horizontal labels at the top
            if(i===1)
            board.push(this.getRowLabels())

            //rednering left side boxes
            for (let j = 1; j < this.props.data.leftChannelPostionsCount+1; j++) {
                let shuttle=false
                if(shuttle_pos.level===this.props.selectedRow && shuttle_pos.side==="left" && shuttle_pos.channel===i&&Math.abs(this.props.data.leftChannelPostionsCount+1-shuttle_pos.location)===j)
                shuttle=true

                //vertical labels at the left side
                if(j===1)
                    one_row.push(<span className="label-vertical" key={"vertical-label-left"+j}>T{("00"+Math.abs(this.props.data.channelsCount-i)).slice(-3)}</span>)
                
                one_row.push(
                <OneSpot shuttle={shuttle?true:false} key={"left"+i + "_" + j} row={i} column={j}  selected={this.state.selected} selectedBox={this.selectedBox} currentLevel={this.props.selectedRow}
                />
                )
            }

            //rednering aisle between left and right side
            if(this.props.selectedRow===this.props.mover.position.level && this.props.mover.position.channel===i)
                one_row.push(<OneAisle key={"aisle"+i} mover={true}/>)
            else if(this.props.selectedRow===this.props.shuttle.position.level && this.props.shuttle.position.location===0 && this.props.shuttle.position.channel===i)
                one_row.push(<OneAisle key={"aisle"+i} shuttle={true}/>)
            else
            one_row.push(<OneAisle key={"aisle"+i} />)

            //rednering right side boxes
            for (let j = 1; j < this.props.data.rightChannelPostionsCount+1; j++) {    
                let shuttle=false
                if(shuttle_pos.level===this.props.selectedRow && shuttle_pos.side==="right" && shuttle_pos.channel===i&&shuttle_pos.location===j)
                shuttle=true
                
                one_row.push(
                <OneSpot key={"right"+i + "_" + j} shuttle={shuttle?true:false} row={i} column={j+this.props.data.leftChannelPostionsCount} selected={this.state.selected} selectedBox={this.selectedBox}
                currentLevel={this.props.selectedRow}
                />
                )

                //vertical labels at the right side
                if(j===this.props.data.rightChannelPostionsCount)
                    one_row.push(<span className="label-vertical" key={"vertical-label-right"+j}>T{("00"+Math.abs(2*this.props.data.channelsCount-i)).slice(-3)}</span>)
            }

            //pushing row to array
            board.push(<div key={"board-above-"+i} className="d-flex flex-row">{one_row}</div>)
        }
        return (
            <div key={"above-view-container"} className="d-flex flex-column pb-5 pt-5 align-items-center">
                <p>Widok z góry : poziom {this.props.selectedRow}</p>
                {board}
            </div>
        )
    }
}

export default AboveView
