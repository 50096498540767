import React, { Component } from 'react'
import OneSpot from './OneSpot'

class Bufor extends Component {
  render() {
    let steps=[]
    for(let i=1; i<12;i++){
        if(i<=this.props.busy)
            steps.push(<OneSpot occupated={true}/>)
        else
            steps.push(<OneSpot/>) 
    }
    return (
        <div className="d-flex flex-column pt-5 pb-5 align-items-center">
            <p>Bufory</p>
            <div className="d-flex flex-row">{steps}</div>
        </div>
    )
  }
}

export default Bufor
