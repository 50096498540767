import React, { Component } from 'react'

class OneSpot extends Component {

    constructor() {
        super()
        this.state = { class_name: "one-box spot", selected_now: false, prevProps: null }
    }

    componentDidMount = () => {
        if (this.props.occupated)
            this.setState({ class_name: "one-box spot occupated" })

        else if (this.props.shuttle)
            this.setState({ class_name: "one-box spot shuttle icon-aisle" })

        else if (this.props.hovered)
            this.setState({ class_name: "one-box spot hovered" })
    }

    componentDidUpdate = (prevProps, prevState) => {
        let first_load = false

        //if prev props are not set - set it
        if (!this.state.prevProps) {
            this.setState({ prevProps: this.props })
            first_load = true
        }

        //compare current props with previous    
        if (this.props !== this.state.prevProps || first_load) {

            //update props state, check if box is selected
            this.setState({ prevProps: this.props, selected_now: this.state.class_name === "one-box spot selected" ? true : false })

            //update class name to normal, then:
            this.setState({ class_name: "one-box spot" }, () => {
                //add neccessary classes
                if (this.props.occupated)
                    this.setState({ class_name: "one-box spot occupated" })

                else if (this.props.shuttle)
                    this.setState({ class_name: "one-box spot shuttle icon-aisle" })

                else if (this.props.hovered)
                    this.setState({ class_name: "one-box spot hovered" })


                //check if the box should be selected
                if (this.props.selected && this.props.selected.row === this.props.row && this.props.selected.column === this.props.column) {

                    //if it isnt selected right now - select it
                    if (!this.state.selected_now) {
                        this.setState({ class_name: "one-box spot selected" })
                        this.setState({ selected_now: true })
                    }

                    //if it selected right now - unselect it
                    else {
                        this.props.selectedBox(null, null)
                        this.setState({ selected_now: false })
                    }
                }

            })
        }
    }

    render() {
        return (
            <div className={this.state.class_name} onClick={this.props.selectedBox && !this.props.shuttle ?
                () => {
                    this.props.selectedBox(this.props.row, this.props.column)
                }
                : undefined}>

            </div>
        )
    }
}

export default OneSpot
