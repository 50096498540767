import React, { Component } from 'react'
import SideView from './SideView'
import AboveView from './AboveView'
import Bufor from './Bufor'
import Header from './Header'
import SideBar from './SideBar'

let data = {
    warehouse_config: {
        levelsCount: 5,
        channelsCount: 7,
        leftChannelPostionsCount: 6,
        rightChannelPostionsCount: 8
    },
    elevator: {
        position: {
            level: "A"
        }
    },
    mover: {
        position: {
            level: "B",
            channel: 4
        }
    },
    shuttle: {
        position: {
            level: "A",
            channel: 3,
            location: 3,
            side: "right"
        }
    }
}

class MainContainer extends Component {
    constructor() {
        super()
        this.state = {
            row: "A", levels: data.warehouse_config.levelsCount, channels: data.warehouse_config.channelsCount,
            right: data.warehouse_config.rightChannelPostionsCount, left: data.warehouse_config.leftChannelPostionsCount, update: false
        }
    }

    selectRow = (index) => {
        this.setState({ row: index })
    }

    handleWarehouseChange = (e) => {
        e.preventDefault()
        data.warehouse_config = {
            levelsCount: parseInt(this.state.levels),
            channelsCount: parseInt(this.state.channels),
            leftChannelPostionsCount: parseInt(this.state.left),
            rightChannelPostionsCount: parseInt(this.state.right)
        }
        this.setState({ update: !this.state.update })
    }

    render() {
        return (
            <div className="d-flex flex-column">
            <Header/>   
            <div className="d-flex flex-row justify-content-between main-container">
            <SideBar/>
            <div className="d-flex flex-column align-items-center warehouse-content">
                <AboveView selectedRow={this.state.row} data={data.warehouse_config} mover={data.mover} shuttle={data.shuttle}/>
                <SideView selectRow={this.selectRow} data={data.warehouse_config} elevator={data.elevator} mover={data.mover} shuttle={data.shuttle}/>
                <Bufor busy={2}/>
            </div>
                <form className="d-flex flex-column pt-5">
                    <label>Poziomy: </label>
                    <input value={this.state.levels} type="number" min="1" onChange={(e)=>{this.setState({levels:e.target.value})}}/>
                    <label>Kanały: </label>
                    <input value={this.state.channels} type="number" min="1" onChange={(e)=>{this.setState({channels:e.target.value})}}/>
                    <label>Kanały z lewej: </label>
                    <input value={this.state.left} type="number" min="1" onChange={(e)=>{this.setState({left:e.target.value})}}/>
                    <label>Kanały z prawej: </label>
                    <input value={this.state.right} type="number" min="1" onChange={(e)=>{this.setState({right:e.target.value})}}/>
                    <input type="submit" value="Generuj" className="mt-3 button-submit" onClick={this.handleWarehouseChange}/>
                </form>
            </div>
            </div>

        )
    }
}

export default MainContainer
