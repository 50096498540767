import React from 'react';
import './App.css';
import MainContainer from './components/MainContainer'
import 'semantic-ui-css/semantic.min.css'
import Login from './components/Login'

let logged = false

class App extends React.Component {
  constructor(){
    super()
    this.state={reload:true}
  }

  handleLogin=()=>{
    logged=true;
    this.setState({reload:!this.state.reload})
  }

  render() {
    return (
      <div>
        {logged ?
          <MainContainer />
          :
          <Login userLogged={this.handleLogin} />
        }

      </div>
    );
  }
}

export default App;
