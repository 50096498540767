import React from 'react';
import logo from '../img/logo.jpg'

function Header(props) {
    return (
        <div className="header">
            <div className="image-container">
            <img src={logo} alt="mfc logo"/>
            </div>
        </div>
    );
}

export default Header;