import React, { Component } from 'react';
import $ from 'jquery'
import { Icon } from 'semantic-ui-react'

class Login extends Component {
    constructor() {
        super()
        this.state = { login: "", password: "" }
    }

    handleFormSubmit = (e) => {
        e.preventDefault()
        const ref = this
        $.ajax({
            method: "POST",
            data: {
                login: ref.state.login,
                password: ref.state.password
            },
            url: "https://mfc.hxspace.pl/login.php",
            success(res) {
                ref.setState({ login: "", password: "" })
                if (res === "true")
                    ref.props.userLogged()
            },
            error(err) {
                console.log(err)
            }
        })
    }

    render() {
        return (
            <div className="login-container">
                <form onSubmit={this.handleFormSubmit}>
                    <div className="d-flex flex-row justify-content-around mt-3 mb-3 align-items-center">
                        <Icon name="user outline" />Login:
                        <input type="text" value={this.state.login} className="ml-2" onChange={(e) => this.setState({ login: e.target.value })} />
                    </div>
                    <div className="d-flex flex-row justify-content-around mt-3 mb-3 align-items-center">
                        <Icon name="key" />Hasło:
                        <input type="password" value={this.state.password} className="ml-2" onChange={(e) => this.setState({ password: e.target.value })} />
                    </div>
                    <button type="submit" className="mt-3">Zaloguj</button>
                </form>
            </div>
        );
    }
}

export default Login;