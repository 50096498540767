import React from 'react';
import { Icon } from 'semantic-ui-react'

class SideBar extends React.Component {
    constructor(){
        super()
        this.state={radio_button:"hand"}
    }

    handleRadio=(input)=>{
        this.setState({radio_button:input})
    }

    render (){
        return (
        <div className="sidebar d-flex flex-column">
            <div className="d-flex flex-row sidebar-item align-items-center justify-content-start p-3 pl-4" style={{ "boxSizing": "border-box" }}>
                <div className="activity-icon">
                    <Icon fitted name="circle" />
                </div>
                <div className="d-flex flex-column" style={{ "paddingLeft": "20px" }}>
                    <p className="header-text-user" style={{ "margin": "0" }}>start</p>
                    <p className="small-text-user">Administrator</p>
                </div>
            </div>
            <div className="d-flex flex-column sidebar-list-container">

                <div className="pl-4 pt-4 pb-4 border-bottom">
                    <p className="sidebar-list-title">Menu główne</p>
                    <ul className="sidebar-list">
                        <li><Icon name="tachometer alternate" /> Pulpit</li>
                    </ul>
                </div>

                <div className="pl-4 pt-4 pb-4 border-bottom">
                    <p className="sidebar-list-title">Sterowanie</p>
                    <ul className="sidebar-list">
                        <form>
                        <li className="radio-container" onClick={() => this.handleRadio("auto")}>
                            <input type="radio" value="auto" name="handling" checked={this.state.radio_button==="auto"} onChange={()=>{/*onchange needed but not used (span is handler)*/}}/>
                            <span className="checkmark"></span>
                            Automatyczne
                        </li>

                        <li className="radio-container" onClick={() => this.handleRadio("hand")}>
                            <input type="radio" value="hand" name="handling" checked={this.state.radio_button==="hand"} onChange={()=>{/*onchange needed but not used (span is handler)*/}}/>
                            <span className="checkmark"></span>
                            Ręczne
                        </li>
                        </form>
                    </ul>
                </div>

                <div className="pl-4 pt-4 pb-4 border-bottom">
                    <p className="sidebar-list-title">Konfiguracja</p>
                    <ul className="sidebar-list">
                        <li className="pt-2 pb-2"><Icon name="warehouse" /> Magazyn</li>
                        <li className="pt-2 pb-2"><Icon name="cog" /> Uprawienia</li>
                        <li className="pt-2 pb-2"><Icon name="book" /> Słowniki</li>
                    </ul>
                </div>

                <div className="pl-4 pt-4">
                    <p className="sidebar-list-title">Konfiguracja</p>
                    <ul className="sidebar-list">
                        <li className="pt-2 pb-2 button-container"><button>Start <Icon name="play circle outline" /></button></li>
                        <li className="pt-2 pb-2 button-container"><button>Pauza <Icon name="pause circle outline" /></button></li>
                        <li className="pt-2 pb-2 button-container"><button>Stop <Icon name="stop circle outline" /></button></li>
                    </ul>
                </div>

            </div>
        </div>
    );
    }
}

export default SideBar;